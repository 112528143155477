import { PageProps, graphql } from 'gatsby'
import Seo from '../../components/seo'
import MarkdownPage from '../../components/MarkdownPage/MarkdownPage'
import '../../scss/common/what-is-page.scss'

const WhatIsQChem = ({ data }: PageProps<Queries.WhatIsQChemPageQuery>) => {
  if (!data?.markdownRemark) return null

  return (
    <MarkdownPage
      title={data.markdownRemark.frontmatter?.title ?? ''}
      tableOfContents={data.markdownRemark.tableOfContents ?? ''}
      html={data.markdownRemark.html ?? ''}
      className="WhatIsPage"
    />
  )
}

const FALLBACK_PAGE_TITLE = 'What is quantum chemistry? | PennyLane'

export const Head = ({ data }: PageProps<Queries.WhatIsQChemPageQuery>) => {
  return (
    <Seo
      title={
        data.markdownRemark?.frontmatter?.meta_title
          ? data.markdownRemark.frontmatter.meta_title
          : data.markdownRemark?.frontmatter?.title || FALLBACK_PAGE_TITLE
      }
      description={data.markdownRemark?.frontmatter?.meta_description || ''}
      image={data.markdownRemark?.frontmatter?.meta_image || ''}
    />
  )
}

export default WhatIsQChem

export const pageQuery = graphql`
  query WhatIsQChemPage {
    markdownRemark(
      frontmatter: { slug: { eq: "/qml/what-is-quantum-chemistry" } }
    ) {
      frontmatter {
        slug
        title
        meta_title
        meta_description
        meta_image
      }
      tableOfContents
      html
    }
  }
`
