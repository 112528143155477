import { useState, useLayoutEffect } from 'react'
import { useCheckBanner } from 'shared-utilities'
import MathJax from '../MathJax/MathJax'
import './style.scss'

interface IDemosLayoutProps {
  mainSection: JSX.Element
  sidebarSection?: JSX.Element
  className?: string
}

const DemosLayout = ({
  mainSection,
  sidebarSection,
  className = '',
}: IDemosLayoutProps) => {
  const [isBannerPresent, setIsBannerPresent] = useState<boolean>(false)
  const [isDemo, setIsDemo] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (window.location.href.includes('/demos/')) setIsDemo(true)
  }, [])

  useCheckBanner(setIsBannerPresent)

  return (
    <section
      className={`DemosLayout__wrapper ${
        isBannerPresent ? 'DemosLayout__wrapper--bannerVisible' : ''
      } ${className} ${isDemo ? `mainDemoSection` : ''}`}
    >
      {sidebarSection && (
        <div
          className={`sidebarSection ${
            isBannerPresent ? 'sidebarSection--bannerVisible' : ''
          }`}
          data-testid="sidebar-section"
        >
          {sidebarSection}
        </div>
      )}
      <div
        className={`mainSection ${
          sidebarSection ? 'mainSection--left-padding' : ''
        }`}
        data-testid="main-section"
      >
        {mainSection}
      </div>
      <MathJax />
    </section>
  )
}

export default DemosLayout
